.about-container {
    margin-top: 140px
}

.about-image-box {
    position: relative
}

.about-content-text {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 30vw;
}

.about-title {
    font-family: "Marcellus", sans-serif;
    font-size: 2.4vw;
    color: #121F38;
}

.about-lastname {
    color: #5996C5
}

.about-content {
    font-size: 1.2vw;
    margin-top: 24px
}

.about-title-second {
    font-family: "Marcellus", sans-serif;
    font-size: 24px;
    color: #121F38;
    text-align: center;
    padding-bottom: 24px;
}

.about-content-second {
    font-size: 14px;
    margin-top: 24px
}

@media only screen and (max-width: 768px) {
    .about-container {
        margin-top: 100px
    }
}