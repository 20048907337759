.just-line {
    border-bottom: 1px solid #D9D9D990;
    padding-top: 14px;
    padding-bottom: 14px
}

.zero {
    padding-left: 0px;
    padding-right: 0px
}

.social-nav-icon {
    color: #5996C5;
    font-size: 16px;
}

.just-location {
    margin-top: 4px
}



.header-address {
    font-size: 14px !important;
    color: #CACACA;
    padding-left: 14px
}

.social-side {
    text-align: right;
    display: flex;
    justify-content: right;
}

.social-icon {
    color: #5996C5;
    font-size: 16px;
    padding-left: 8px;
    align-self: center;
    padding-top: 2px;
}

.whatsapp-zone {
    padding-left: 24px;
    color: #979797
}

.whatsapp-link {
    color: #CACACA;
    margin-top: -24px;
    padding-left: 8px
}

.whatsapp-link:hover {
    color: #CACACA;
}

.whatsapp-icon {
    color: #05D7A0;
    font-size: 16px;
    padding-left: 8px;
    align-self: center;
    padding-top: 2px;
    text-align: center;
}

.whatsapp-icon2 {
    color: #05D7A0;
    font-size: 16px;
    align-self: center;
    padding-top: 2px;
    text-align: center;
}

.follow-us-text {
    color: #CACACA
}

.my-navbar {
    background-color: white;
    border: 1px solid white;
    padding-top: 14px;
}

.navbar-default .navbar-brand {
    color: #121F38;
    font-family: 'Marcellus' !important;
    font-size: 20px;
}

.last-name {
    color: #5996C5;
}

.navbar-default .navbar-nav>li>a {
    color: #121F38;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.navbar-default .navbar-nav>li>a:hover {
    color: #5996C5;
    transition: 0.3s;
    cursor: pointer;
}

.navbar-default .navbar-nav>li>a>button {
    color: #5996C5;
    font-weight: 500;
    padding: 8px 24px;
    border: 1px solid #5996C5;
    background-color: white;
    margin-top: -16px;
    border-radius: 6px;
}

.navbar-default .navbar-nav>li>a>button:hover {
    color: white;
    font-weight: 500;
    border: 1px solid #5996C5;
    background-color: #5996C5;
    transition: 0.5s;
    cursor: pointer;
}

.dropdown-menu {
    border-radius: 8px;
    border: 1px solid white
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus {
    background-color: #5996C5;
    color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.dropdown-menu>li>a {
    color: #979797;
    cursor: pointer;
}

.dropdown-menu>li>a:hover {
    transition: 0.5s;
}

.navbar-default .navbar-collapse {
    border-color: white
}

@media (min-width: 998px) and (max-width: 1000px) {
    .collapse {
        display: none !important;
    }
}

@media only screen and (max-width: 750px) {
    .header-address-zone {
        text-align: center;
    }

    .header-address-zone2 {
        text-align: justify;
        border-bottom: 0.5px solid #80808040;
        padding-bottom: 6px;
        padding-top: 6px
    }

    .social-side {
        margin-top: 18px;
        text-align: center !important;
        justify-content: center;
    }

    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:focus {
        background-color: white;
        color: inherit;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
}