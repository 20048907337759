.services-content-box {
    display: flex;
    justify-content: center;
    min-height: 500px;
    margin-top: 124px;
    flex-direction: column;
    /* background-color: aqua; */
    padding-left: 15px;
}

.services-first-title {
    color: #5996C5;
    font-size: 13px;
}

.services-title {
    color: #121F38;
    font-size: 36px;
    font-family: "Marcellus", sans-serif;
}

.services-title-color {
    color: #5996C5
}

.services-content {
    color: #979797;
}

.services-button {
    color: #121F38;
    border: 1px solid #121F38;
    width: 200px;
    padding: 10px 15px;
    margin-top: 24px;
    border-radius: 12px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.services-button:hover {
    color: white;
    border: 1px solid #121F38;
    background-color: #121F38;
    transition: 0.5s;
}

.services-photo-wrap {
    margin-top: 100px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-photo-box {
    margin-top: 24px;
    position: relative;
}

.services-photo-box:hover {
    cursor: pointer;
}

.services-photo-button {
    position: absolute;
    bottom: 10%;
    font-family: "Marcellus", sans-serif;
    width: 90%;
    font-size: 24px;
    color: white;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .services-content-box {
        margin-top: 60px;
        min-height: auto;
        padding-bottom: 40px
    }

    .services-photo-wrap {
        margin-top: 40px;
    }
}