.video-container {
    margin-top: 140px;
    background-image: url("../../images/video/video-bg.png");
    background-position: center center;
    background-size: cover;
    height: 600px;
    background-attachment: fixed;

}

.video-icon-box {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-play-button {
    font-size: 80px;
    color: white
}

.video-play-button:hover {
    opacity: 0.8;
    color: #5996C5
}