.just-space {
    margin-left: 8px !important;
}

.carousel-caption {
    position: absolute;
    right: 65%;
    top: 30%;
    left: 12%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
    text-shadow: none;
}

.slider-top-title {
    font-size: 13px;
    color: #5996C5;
    font-family: "Work Sans";
    font-weight: 500;
}

.slider-title {
    font-family: "Marcellus";
    font-size: 40px;
    color: #121F38
}

.second-title {
    color: #5996C5
}

.slider-content {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: 500;
    color: #5996C5
}

.slider-button {
    color: #121F38;
    border: 1px solid #121F38;
    padding: 10px 32px;
    font-weight: 500;
    font-family: "Work Sans";
    margin-top: 24px;
    background-color: #ffffff00;
    border-radius: 12px;
}

.slider-button:hover {
    color: white;
    border: 1px solid #121F38;
    padding: 10px 32px;
    font-weight: 500;
    font-family: "Work Sans";
    margin-top: 24px;
    background-color: #121F38;
    border-radius: 12px;
    transition: 0.5s;
}


@media only screen and (max-width: 1100px) {
    .carousel-caption {
        position: absolute;
        right: 68%;
        top: 20%;
        left: 8%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
        text-shadow: none;
        transition: 0.5s;
    }
}


@media only screen and (max-width: 975px) {
    .carousel-caption {
        position: absolute;
        right: 68%;
        top: 15%;
        left: 8%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
        text-shadow: none;
        transition: 0.5s;
    }
}

@media only screen and (max-width: 750px) {
    .carousel-caption {
        position: absolute;
        right: 68%;
        top: 15%;
        left: 8%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
        text-shadow: none;
        transition: 0.5s;
    }


    .slider-top-title {
        font-size: 1.3vw;
        color: #5996C5;
        font-family: "Work Sans";
        font-weight: 500;
    }

    .slider-title {
        font-family: "Marcellus";
        font-size: 2.9vw;
        color: #121F38;
        margin-top: 4px
    }

    .second-title {
        color: #5996C5
    }

    .slider-content {
        font-size: 1.4vw;
        font-family: "Work Sans";
        font-weight: 500;
        color: #5996C5
    }

    .slider-button {
        color: #121F38;
        border: 1px solid #121F38;
        padding: 5px 10px;
        font-weight: 500;
        font-family: "Work Sans";
        font-size: 1.4vw;
        margin-top: 12px;
        background-color: #ffffff00;
        border-radius: 6px;
    }

    .slider-button:hover {
        color: white;
        border: 1px solid #121F38;
        padding: 5px 10px;
        font-weight: 500;
        font-family: "Work Sans";
        margin-top: 12px;
        background-color: #121F38;
        transition: 0.5s;
    }
}

@media only screen and (max-width: 400px) {
    .carousel-caption {
        top: 10%;
        transition: 0.5s;
    }
}