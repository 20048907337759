.contact-container {
    margin-top: 100px
}

.media-wrapper {
    margin-top: 24px
}

.just-media-title {
    font-family: "Marcellus", sans-serif;
    font-size: 20px;
}