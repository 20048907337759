.details-title {
    font-family: "Marcellus";
    font-size: 40px;
    color: #121F38;
    padding-top: 24px
}

.second-color-title {
    color: #5996C5;
}

.details-button-box {
    margin-top: 24px
}

.details-randevu-button {
    padding: 10px 25px;
}

.genaral-n {
    white-space: pre-wrap;
}

.details-randevu-button:hover {
    color: white;
    background-color: #121F38;
    border: 1px solid #121F38;
    transition: 0.5s;
}

.content-text-box {
    padding-top: 40px
}

.details-second-title {
    color: #121F38;
    font-size: 24px;
    font-family: "Marcellus", sans-serif;
    margin-top: 48px
}

.second-and-second-title {
    color: #5996C5
}

.last-and-last-button {
    margin-top: 12px;
    margin-left: 12px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    border-radius: 14px;
}

.content-text-box p {
    line-height: 28px;
    font-family: "Marcellus", sans-serif;
    text-align: justify;
    white-space: pre-wrap
}

.small-contact-box {
    margin-top: 24px
}

.contact-sub-title {
    font-family: "Marcellus", sans-serif;
}

@media only screen and (max-width: 750px) {
    .details-title {
        font-family: "Marcellus";
        font-size: 40px;
        color: #121F38;
        text-align: center;
    }
}