.footer-container {
    background-color: white;
    background-image: url("../../images/footer/footer-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 600px;
}

.footer-outside {
    margin-top: 140px
}

.last-item-footer {
    background-color: #121F38;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.first-footer-box {
    margin-top: 80px
}

.second-footer-box {
    margin-top: 80px
}

.footer-about-title {
    color: #121F38;
    font-size: 20px;
    font-family: "Marcellus", sans-serif;
    margin-top: 24px
}

.footer-second-title {
    color: #5996C5
}

.footer-about-text {
    color: #121F38
}

.footer-contact-info {
    font-size: 20px;
    font-family: "Marcellus", sans-serif;
    color: #5996C5;
    margin-top: 60px
}

.footer-menu-title {
    color: #5996C5;
    font-size: 20px;
    font-family: "Marcellus", sans-serif;
}

.footer-list {
    list-style: none;
}

.footer-list a {
    color: #121F38;
    font-weight: 500;
}

.footer-list li {
    padding-top: 16px
}

.footer-last-section {
    margin-top: 40px;
    padding-bottom: 40px
}

.footer-last-title {
    font-family: "Marcellus", sans-serif;
    font-size: 20px;
    padding-bottom: 14px
}